import api from '@/base/utils/request';

export const saveWxRobotConfig = (data) => {
    return api({
        url: '/admin/wx_open/settings/saveWxRobotConfig',
        method: 'post',
        data
    })
}
export const getWxRobotConfig = (data) => {
    return api({
        url: '/admin/wx_open/settings/getWxRobotConfig',
        method: 'post',
        data
    })
}

