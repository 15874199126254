<!--
 * @Author: dongjia
 * @Date: 2022-04-19 09:49:36
 * @LastEditTime: 2022-04-19 10:43:46
 * @LastEditors: aleaner
 * @Description: 商会共享弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\admin\components\ServerTipsDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="myConfigData.name"
    :visible.sync="openDialog"
    width="1400px"
    class="dialog-vertical"
    close-on-click-modal
    close-on-press-escape
    :show-close="false"
  >
    <div class="dialog-box">
      <div class="content-box" v-if="myConfigData">
        <el-input
          class="content-input"
            @blur="inputBlur"
          :autosize="{ minRows: 18, maxRows: 20 }"
          type="textarea"
          placeholder="请输入内容"
          v-model="myConfigData.content_config.content"
          show-word-limit
        >
        </el-input>
        <div class="setting-box">
          <div class="setting-title">变量插入</div>
          <div class="params-list">
            <div
              class="item"
              v-for="item in myConfigData.extra_config.content_show"
            >
              <el-button @click="addContent(item)" type="primary">{{
                item.name
              }}</el-button>
            </div>
          </div>
          <div class="setting-list">
            <div class="item">
              <el-form>
                <el-form-item label="每日动态发布时间：">
                  <div class="flex-box">
                    每天的

                    <el-time-picker
                      :style="{
                        marginLeft: '12px',
                      }"
                      :value-format="'timestamp'"
                      v-model="myConfigData.extra_config.send_timestamp"
                      placeholder="任意时间点"
                    >
                    </el-time-picker>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dataSelector
      v-model="showSelectDialog"
      dataType="st-spirit-list"
      selectFullData
      @change="dataChange"
      @cancel="dataCancel"
    />
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="comfirm" v-loading="saveLoading"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
const resUrl = window.serverConfig.VUE_APP_ADMINURL
import dataSelector from '@/modules/app-design/components/Normal/dataSelector.vue'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  components: {
    dataSelector,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    configData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resUrl,
      showSelectDialog: false,
      saveLoading: false,
      myConfigData: '',
      weekOptions: [
        { name: '星期一', value: 1 },
        { name: '星期二', value: 2 },
        { name: '星期三', value: 3 },
        { name: '星期四', value: 4 },
        { name: '星期五', value: 5 },
        { name: '星期六', value: 6 },
        { name: '星期日', value: 7 },
      ],
      blurIndex: 0,
    }
  },
  methods: {
       // 输入框失去焦点
       inputBlur(val) {
      this.blurIndex = val.srcElement.selectionStart
    },
    // 保存
    comfirm() {
      // 将myConfigData中的数据赋值给configData

      for (const key in this.$props.configData) {
        this.$props.configData[key] = JSON.parse(
          JSON.stringify(this.myConfigData[key])
        )
      }
      // 时间戳转换为毫秒
      this.$props.configData.extra_config.send_timestamp =
        this.$props.configData.extra_config.send_timestamp / 100

      this.$emit('save', this.myConfigData)

      console.log(this.myConfigData, 'this.myConfigData')
      this.openDialog = false
    },
    // 取消
    cancel() {
      this.openDialog = false
    },
    addContent(item) {
      console.log(item, this.blurIndex)

// console.log(start, end)
// // 更新输入框的值，插入文本
this.myConfigData.content_config.content =
  this.myConfigData.content_config.content.slice(0, this.blurIndex) +
  item +
  this.myConfigData.content_config.content.slice(this.blurIndex)
    },
    // 单选框改变
    radioChange(val) {
      console.log(val, 'val')
      if (val === 1) {
        this.showSelectDialog = true
      }
    },
    // 数据选择
    dataChange(val) {
      console.log(val)
      this.myConfigData.extra_config.user_ids = val.map((item) => item.id)
    },
    // 弹窗取消
    dataCancel() {
      // 如果是自定义，选择会员，但是没有选择数据，需要将user_type改为0
      if (this.myConfigData.extra_config.user_type === 1) {
        this.myConfigData.extra_config.user_type = 0
      }
    },
  },
  created() {
    this.myConfigData = JSON.parse(JSON.stringify(this.$props.configData))
    this.myConfigData.extra_config.send_timestamp =
      this.myConfigData.extra_config.send_timestamp * 100
  },
  watch: {
    // 监听
    openDialog: {
      handler(val) {
        console.log(val, 'val')
        if (val) {
          this.myConfigData = JSON.parse(JSON.stringify(this.$props.configData))
          this.myConfigData.extra_config.send_timestamp =
            this.myConfigData.extra_config.send_timestamp * 100
        }
      },
      deep: true,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  .content-input {
    width: 600px;
  }
  .setting-box {
    flex: 1;
    margin-left: 24px;
    .setting-title {
      font-size: 14px;
      color: #1a1a1a;
      margin-bottom: 12px;
    }
    .params-list {
      margin-bottom: 24px;

      .item {
        color: aqua;
      }
      .item + .item {
        margin-top: 12px;
      }
    }
  }
}

.flex-box {
  display: flex;
  align-items: center;
}
</style>
