<!--
 * @Author: dongjia
 * @Date: 2022-04-19 09:49:36
 * @LastEditTime: 2022-04-19 10:43:46
 * @LastEditors: aleaner
 * @Description: 商会共享弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\admin\components\ServerTipsDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="myConfigData.name"
    :visible.sync="openDialog"
    width="1200px"
    class="dialog-vertical"
    close-on-click-modal
    close-on-press-escape
    :show-close="false"
  >
    <div class="dialog-box" v-if="myConfigData">
      <div class="content-box">
        <el-input
          class="content-input"
            @blur="inputBlur"
          :autosize="{ minRows: 18, maxRows: 20 }"
          type="textarea"
          placeholder="请输入内容"
          v-model="myConfigData.content_config.content"
          show-word-limit
        >
        </el-input>
        <div class="setting-box">
          <div class="setting-title">变量插入</div>
          <div class="params-list">
            <div
              class="item"
              v-for="item in myConfigData.content_config.params"
            >
              <el-button @click="addContent(item)" type="primary">{{
                item
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <dataSelector
      v-model="showSelectDialog"
      dataType="st-circle-topic"
      selectFullData
      @change="dataChange"
      @cancel="dataCancel"
    /> -->
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="comfirm" v-loading="saveLoading"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
const resUrl = window.serverConfig.VUE_APP_ADMINURL
import { recordMsgCount } from '@/modules/admin/api/header'
import dataSelector from '@/modules/sms/components/dataSelectorRadio.vue'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  components: {
    dataSelector,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    configData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resUrl,
      showSelectDialog: false,
      saveLoading: false,
      myConfigData: '',
      blurIndex: 0,
    }
  },
  methods: {
       // 输入框失去焦点
       inputBlur(val) {
      this.blurIndex = val.srcElement.selectionStart
    },
    // 保存
    comfirm() {
      // 将myConfigData中的数据赋值给configData
      for (const key in this.$props.configData) {
        this.$props.configData[key] = this.myConfigData[key]
      }
      this.$emit('save', this.configData)

      console.log(this.configData, 'this.configData')
      this.openDialog = false
    },
    // 取消
    cancel() {
      this.openDialog = false
    },
    addContent(item) {
      console.log(item, this.blurIndex)

      // console.log(start, end)
      // // 更新输入框的值，插入文本
      this.myConfigData.content_config.content =
        this.myConfigData.content_config.content.slice(0, this.blurIndex) +
        item +
        this.myConfigData.content_config.content.slice(this.blurIndex)
    },
    // 单选框改变
    radioChange(val) {
      console.log(val, 'val')
      if (val === 2) {
        this.showSelectDialog = true
      }
    },
    // 数据选择
    dataChange(val) {
      console.log(val)
      this.myConfigData.extra_config.post_id = val[0].id
    },
    // 弹窗取消
    dataCancel() {
      // 如果是自定义，选择帖子，但是没有选择数据，需要将post_type改为0
      if (this.myConfigData.extra_config.post_type === 2) {
        this.myConfigData.extra_config.post_type = 0
      }
    },
  },
  created() {},
  watch: {
    // 监听
    openDialog: {
      handler(val) {
        console.log(val, 'val')
        if (val) {
          this.myConfigData = JSON.parse(JSON.stringify(this.$props.configData))
        }
      },
      deep: true,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  .content-input {
    width: 600px;
  }
  .setting-box {
    flex: 1;
    margin-left: 24px;
    .setting-title {
      font-size: 14px;
      color: #1a1a1a;
      margin-bottom: 12px;
    }
    .params-list {
      margin-bottom: 24px;

      .item {
        color: aqua;
      }
      .item + .item {
        margin-top: 12px;
      }
    }
  }
}

.flex-box {
  display: flex;
  align-items: center;
}
.tips {
  font-size: 12px;
  color: #c0c4cc;
}
</style>
