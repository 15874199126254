<template>
  <div class="body" v-loading="showLoading">
    <reminder title="温馨提示" text="">
          <el-row>
            <el-col :span="23">
              <div>
                <a
                  class="reminder-link"
                  href="https://www.yuque.com/ax101w/yz6xgk/it0llz8mew1fxuya"
                  target="_blank"
                  >群机器人开通流程</a
                >
              </div>
            </el-col>
          </el-row>
        </reminder>
    <el-form
      size="medium"
      :model="form"
      label-position="left"
      label-width="120px"
      class="form"
      ref="form"
      @submit.native.prevent
      v-if="form"
    >
      <div class="filter-item">
        <h2 class="title">会员群设置</h2>
        <el-form-item label="群名：">
          <el-input
            style="width: 200px"
            v-model="form.send_group"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
      </div>
      <h2 class="title">机器人通知选项</h2>
      <div class="content-config-list">
        <div class="item" v-for="item in form.list" :key="item.key">
          <div class="label">{{ item.name }}</div>
          <el-switch
            class="switch"
            v-model="item.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <el-button type="primary" @click="configClick(item)"
            >文案配置</el-button
          >
          <!-- 社区动态  -->
          <CircleTrendDialog
            ref="circleTrendDialog"
            v-if="item.key === 'circle_trend'"
            :configData="item"
            @save="handleSave"
            v-model="showCircleTrendDialog"
          ></CircleTrendDialog>
          <!-- 线下活动上新  -->
          <OfflineActivityNewsDialog
            ref="OfflineActivityNewsDialog"
            v-if="item.key === 'offline_activity_news'"
            :configData="item"
            @save="handleSave"
            v-model="showOfflineActivityNewsDialog"
          ></OfflineActivityNewsDialog>
          <!-- 线上活动上新  -->
          <OnlineActivityNewsDialog
            ref="OnlineActivityNewsDialog"
            v-if="item.key === 'online_activity_news'"
            :configData="item"
            @save="handleSave"
            v-model="showOnlineActivityNewsDialog"
          ></OnlineActivityNewsDialog>
          <!-- 文章发布  -->
          <ArticleNewsDialog
            ref="ArticleNewsDialog"
            v-if="item.key === 'article_news'"
            :configData="item"
            @save="handleSave"
            v-model="showArticleNewsDialogDialog"
          ></ArticleNewsDialog> 
          
          <!-- 新的供需  -->
          <CycCircleNewsDialog
            ref="CycCircleNewsDialog"
            v-if="item.key === 'cyc_circle_news'"
            :configData="item"
            @save="handleSave"
            v-model="showCycCircleNewsDialog"
          ></CycCircleNewsDialog>
          <!-- 会员推荐-->
          <CycMemberRecommendDialog
            ref="CycMemberRecommendDialog"
            v-if="item.key === 'cyc_member_recommend'"
            :configData="item"
            @save="handleSave"
            v-model="showCycMemberRecommendDialog"
          ></CycMemberRecommendDialog>
          <!-- 新入会会员-->
          <CycNewMemberDialog
            ref="CycNewMemberDialog"
            v-if="item.key === 'cyc_new_member'"
            :configData="item"
            @save="handleSave"
            v-model="showCycNewMemberDialog"
          ></CycNewMemberDialog>
          <!-- 每周动态-->
          <WeeklyTrendDialog
            ref="WeeklyTrendDialog"
            v-if="item.key === 'weekly_trend'"
            :configData="item"
            @save="handleSave"
            v-model="showWeeklyTrendDialog"
          ></WeeklyTrendDialog>
          <!-- 每日动态-->
          <DailyTrendDialog
            ref="DailyTrendDialog"
            v-if="item.key === 'daily_trend'"
            :configData="item"
            @save="handleSave"
            v-model="showDailyTrendDialog"
          ></DailyTrendDialog>
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="handleSave" :loading="saveLoading"
        >保存</el-button
      >
    </FixedActionBar>

    <!-- 社区动态 -->
  </div>
</template>

<script>
import { getWxRobotConfig, saveWxRobotConfig } from '../api/wx-robot-settings'
import FixedActionBar from '@/base/layout/FixedActionBar'

import CircleTrendDialog from '@/modules/sms/components/CircleTrendDialog'
import OfflineActivityNewsDialog from '@/modules/sms/components/OfflineActivityNewsDialog'
import OnlineActivityNewsDialog from '@/modules/sms/components/OnlineActivityNewsDialog'
import ArticleNewsDialog from '@/modules/sms/components/ArticleNewsDialog'
import CycCircleNewsDialog from '@/modules/sms/components/CycCircleNewsDialog'
import CycMemberRecommendDialog from '@/modules/sms/components/CycMemberRecommendDialog'
import CycNewMemberDialog from '@/modules/sms/components/CycNewMemberDialog'
import WeeklyTrendDialog from '@/modules/sms/components/WeeklyTrendDialog'
import DailyTrendDialog from '@/modules/sms/components/DailyTrendDialog'
import Reminder from '@/modules/common/components/Reminder'

export default {
  components: {
    FixedActionBar,
    CircleTrendDialog,
    OfflineActivityNewsDialog,
    ArticleNewsDialog,
    CycCircleNewsDialog,
    CycMemberRecommendDialog,
    CycNewMemberDialog,
    WeeklyTrendDialog,
    OnlineActivityNewsDialog,
    DailyTrendDialog,
    Reminder
  },

  data() {
    return {
      form: {
        list: [],
        send_group: '',
      },
      showLoading: false,
      saveLoading: false,
      // 社区动态弹窗
      showCircleTrendDialog: false,
      // 线下活动上新弹窗
      showOfflineActivityNewsDialog: false,
      // 线上活动上新弹窗
      showOnlineActivityNewsDialog: false,
      // 文章发布
      showArticleNewsDialogDialog: false,
      // 新的供需
      showCycCircleNewsDialog: false,
      // 会员推荐
      showCycMemberRecommendDialog: false,
      // 新入会会员
      showCycNewMemberDialog: false,
      // 每周动态
      showWeeklyTrendDialog: false,
      // 每日动态
      showDailyTrendDialog: false,
    }
  },
  computed: {},
  methods: {
    getConfig() {
      this.showLoading = true
      getWxRobotConfig()
        .then((res) => {
          this.form = res.data
          this.showLoading = false
        })
        .catch((err) => {
          this.showLoading = false
        })
    },
    // 保存
    handleSave() {
      console.log(this.form)

      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          this.showLoading = true

          saveWxRobotConfig({
            config: this.form,
          })
            .then((res) => {
              this.$message({
                message: '保存成功',
                type: 'success',
              })
            })
            .catch((err) => {})
            .finally(() => {
              this.saveLoading = false
              this.showLoading = false
            })
        }
      })
    },
    // 配置文案
    configClick(item) {
      if (item.key === 'circle_trend') {
        this.showCircleTrendDialog = true
      } else if (item.key === 'offline_activity_news') {
        this.showOfflineActivityNewsDialog = true
      } else if (item.key === 'online_activity_news') {
        this.showOnlineActivityNewsDialog = true
      } else if (item.key === 'article_news') {
        this.showArticleNewsDialogDialog = true
      } else if (item.key === 'cyc_circle_news') {
        this.showCycCircleNewsDialog = true
      } else if (item.key === 'cyc_member_recommend') {
        this.showCycMemberRecommendDialog = true
      } else if (item.key === 'cyc_new_member') {
        this.showCycNewMemberDialog = true
      } else if (item.key === 'weekly_trend') {
        this.showWeeklyTrendDialog = true
      } else if (item.key === 'daily_trend') {
        this.showDailyTrendDialog = true
      }
    },
  },
  created() {
    this.getConfig()
  },
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  .title {
    padding-left: 8px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1;
    border-left: 3px solid #3479ef;
    margin-bottom: 32px;
  }
}
.content-config-list {
  .item {
    display: flex;
    align-items: center;
    .label {
      margin-right: 12px;
      min-width: 100px;
    }
    .switch {
      margin-right: 34px;
    }
  }
  .item + .item {
    margin-top: 24px;
  }
}
</style>
